import { RouteObject } from 'react-router-dom';

import { Home } from './Home/Home';

import App from './App';

export const rootRoutes: RouteObject[] = [
  { path: '/', element: <App />, children: [
    { path: '/', element: <Home /> },
    { path: '/lesson1', element: <>Beans</> }
  ] }
];

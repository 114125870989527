import { useCallback, useRef } from 'react';

/** 
 * Recommend disabling React's exhaustive-deps lint rule if updateRef is the only dependency in useEffect hook;
 * exhaustive-deps is not smart enough to support custom hooks that wrap safe hooks: https://github.com/facebook/react/issues/20205 
*/
export function useRefMerge<T>(initial: T) {
  const ref = useRef(initial);

  const updateRef = useCallback((updates: Partial<T>) => {
    ref.current = { ...ref.current, ...updates };
  }, []);

  return [ref.current, updateRef] as const;
}

import { createContext, ReactNode, useContext } from 'react';

import { DataService } from './data.service';

const DataServiceContext = createContext<DataService>(null as any);

export function DataServiceProvider({ children }: { children?: ReactNode }) {
  const dataService = new DataService();

  return <DataServiceContext.Provider value={dataService}>{children}</DataServiceContext.Provider>;
}

export function useDataService() {
  return useContext(DataServiceContext);
}

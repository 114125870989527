import { Temtem, TemTypeEnum } from 'models';

import { TemTypeDonutChart } from './TemTypeDonutChart/TemTypeDonutChart';
import { TemTypeBadge } from '../TemTypeBadge/TemTypeBadge';

import './dashboard.scss';

export function Dashboard({ temtem }: { temtem: Temtem[] }) {
  // const grouped = Temtem.groupByType(temtem);

  return (
    <div className="dashboard">
      <div className="dashboard-section">
        <TemTypeDonutChart temtem={temtem} />
        <div className="tem-type-legend">
          { Object.values(TemTypeEnum).map(t => <TemTypeBadge type={t} detailed />) }
        </div>
      </div>
    </div>
  );
}

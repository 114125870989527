import * as superagent from 'superagent';

export class HttpService {
  private baseApiUrl = 'https://temtem-api.mael.tech/api/';

  async get<R>(path: string) {
    return await superagent.get(this.baseApiUrl + path).then(res => res.body as R);
  }

}

import { Temtem, TemtemResponse, TemTypeEnum } from 'models';

import { ApiService } from './api.service';

export class TemtemService extends ApiService {
  get() {
    return this.http.get<TemtemResponse[]>('temtems').then(t => t.map(this.transform));
  }

  private transform(res: TemtemResponse) {
    const result: Temtem = {
      name: res.name,
      number: res.number,
      types: res.types.map(t => TemTypeEnum[t]),
      stats: {
        hp: res.stats.hp,
        stamina: res.stats.sta,
        speed: res.stats.spd,
        attack: res.stats.atk,
        defense: res.stats.def,
        specialAttack: res.stats.spatk,
        specialDefense: res.stats.spdef
      }
    };
    return result;
  }
}

import { Temtem } from 'models';
import { padNumber } from 'utils';

import { TemPortrait } from '../TemPortrait/TemPortrait';
import { TemTypeBadge } from '../TemTypeBadge/TemTypeBadge';

import './tem-list.scss';

interface TemListProps {
  temtem: Temtem[];
}

// TODO: Maybe move info into reusable component
export function TemList(props: TemListProps) {

  // TODO: Virtual scrolling?
  return <>
    <ul className="tem-list">
      { props.temtem?.map(tem => (
        <li className="tem-list-item" key={`${tem.name}:${tem.number}`}>
          <TemPortrait temtem={tem} />
          <div className="tem-info">
            <div className="tem-name">{tem.name}</div>
            <div className="tem-number">{padNumber(tem.number, 3)}</div>
          </div>
          <div className="tem-types">
            { tem.types.map(type => <TemTypeBadge type={type} key={`${tem.name}:${type}`} />) }
          </div>
        </li>
      ))}
    </ul>
  </>;
}

import { TemtemService } from './api';
import { HttpService } from './http.service';

interface ApiServices {
  temtem: TemtemService;
}

export class DataService {
  private http = new HttpService();
  private serviceCache: { [K in keyof ApiServices]?: ApiServices[K]; } = { };

  get temtem() { return this.create('temtem', TemtemService); }

  private create<K extends keyof ApiServices>(name: K, api: new (http: HttpService) => ApiServices[K]) {
    const cached = this.serviceCache[name];
    if (cached) { return cached as ApiServices[K]; }

    this.serviceCache[name] = new api(this.http);
    return this.serviceCache[name] as ApiServices[K];
  }

}

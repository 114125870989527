import { TemTypeEnum, TemTypes } from './enum/tem-type.enum';
import { TemStats } from './tem-stats';

export class Temtem {
  name: string;
  number: number;
  types: TemTypeEnum[];
  stats: TemStats;
  // TODO: Flesh out with more info

  static groupByType(temtem: Temtem[]): TemTypeAggregate[] {
    const result: { [K in TemTypes]?: number } = { };
    const types = temtem.flatMap(t => t.types);
    Object.keys(TemTypeEnum).forEach((type => {
      result[type as TemTypes] = types.filter(t => t === type).length;
    }));
    return Object.entries(result).map(a => ({ type: a[0], count: a[1] }));
  }
  
}

export type TemTypeAggregate = { type: string, count: number };

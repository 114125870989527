import { TemTypeEnum } from 'models';
import { useRef } from 'react';

import './tem-type-badge.scss';

interface TemTypeBadgeProps {
  type: TemTypeEnum;
  detailed?: boolean;
  onClick?: (type: TemTypeEnum) => void;
}

export function TemTypeBadge({ type, detailed, onClick }: TemTypeBadgeProps) {
  const ref = useRef<HTMLDivElement>(null);

  let classes = 'tem-type-badge';
  let backgroundColor = 'transparent';

  if (detailed) { 
    classes += ' tem-type-badge-detailed';
    backgroundColor = `var(--color-type-${type.toLowerCase()})`;
  }

  const path = `${process.env.PUBLIC_URL}/types/${type}.png`;

  function handleClick() {
    if (!onClick) { return; }
    onClick(type);
  }

  return <div className={classes} ref={ref} style={{ backgroundColor }} onClick={handleClick}>
    <img src={path} alt={`Tem ${type} Type Icon`} />
    { detailed ?
      <div>{type}</div> : null
    }
  </div>;
}

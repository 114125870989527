import { Outlet } from 'react-router-dom';

import { Navbar } from './Navbar';

import '../styles/app.scss';

export default function App() {
  return (
    <div className="app">
      <div className="header">
        {/* <Navbar /> */}
      </div>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

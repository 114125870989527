import React, { cloneElement, ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { rootRoutes } from './components/Routes';
import { DataServiceProvider } from './services';

const router = createBrowserRouter(rootRoutes);

function ContextProviderFactory({ providers }: { providers: ReactElement[] }) {
  return providers.reduceRight((children, parent) => cloneElement(parent, { children }));
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ContextProviderFactory providers={[
    <DataServiceProvider />,
    <RouterProvider router={router} />,
  ]} />
);

import { Temtem } from 'models';
import { padNumber } from 'utils';

import './tem-portrait.scss';

interface TemPortraitProps {
  temtem: Temtem;
}

export function TemPortrait({ temtem }: TemPortraitProps) {

  const formattedNum = padNumber(temtem.number, 3);
  // TODO: Spinner when loading
  const path = `${process.env.PUBLIC_URL}/portraits/M${formattedNum}_P.png`;

  return (
    <div className="tem-portrait">
      <img src={path} alt={`Tem ${temtem.name} Portrait`} />
    </div>
  );
}

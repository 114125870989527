export enum TemTypeEnum {
  Neutral = 'Neutral',
  Wind = 'Wind',
  Earth = 'Earth',
  Water = 'Water',
  Fire = 'Fire',
  Nature = 'Nature',
  Electric = 'Electric',
  Mental = 'Mental',
  Digital = 'Digital',
  Melee = 'Melee',
  Crystal = 'Crystal',
  Toxic = 'Toxic'
}

export type TemTypes = 'Neutral' | 'Wind' | 'Earth' | 'Water' | 'Fire' |  'Nature' | 'Electric' | 'Mental' | 'Digital' | 'Melee' | 'Crystal' | 'Toxic';

import { useEffect, useState } from 'react';

import { useDataService } from 'services';
import { Temtem } from 'models';

import { Dashboard } from '../Dashboard/Dashboard';
import { TemSearch } from '../Search/TemSearch';
import { TemList } from '../TemList/TemList';

import './home.scss';

export function Home() {
  const dataService = useDataService();
  const [temtem, setTemtem] = useState<Temtem[]>([]);
  const [filtered, setFiltered] = useState<Temtem[]>([]);

  useEffect(() => {
    async function getTems() {
      const tems = await dataService.temtem.get();
      setTemtem(tems);
      setFiltered(tems);
    }

    if (dataService) { getTems(); }
  }, [dataService]);

  return <div className="home">
    <div className="controls">
      <TemSearch temtem={temtem} onFiltered={f => setFiltered(f)} />
      <TemList temtem={filtered} />
    </div>
    <Dashboard temtem={filtered} />
  </div>;
}

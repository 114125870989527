import * as d3 from 'd3';
import { Arc, Pie, PieArcDatum, ScaleOrdinal, Selection } from 'd3';
import { useEffect, useRef } from 'react';

import { Temtem, TemTypeAggregate, TemTypeEnum } from 'models';
import { arcTween, useRefMerge } from 'utils';

import './tem-type-donut-chart.scss';

interface ChartRefs {
  container?: Selection<SVGGElement, unknown, null, undefined>;
  colorScale?: ScaleOrdinal<string, string, never>;
  pieGen?: Pie<any, TemTypeAggregate>;
  sectorGen?: Arc<any, PieArcDatum<TemTypeAggregate>>;
  labelGen?: Arc<any, PieArcDatum<TemTypeAggregate>>;
}

// TODO: What to show if no temtem in data set?
export function TemTypeDonutChart({ temtem }: { temtem: Temtem[] | null }) {
  const svgRef = useRef<SVGSVGElement>(null);
  const [ref, updateRef] = useRefMerge<ChartRefs>({});

  useEffect(() => {
    const width = 400;
    const height = 400;
    const radius = Math.min(width, height) / 2;

    const container = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const colorScale = d3
      .scaleOrdinal(Object.values(TemTypeEnum).map(t => `var(--color-type-${t.toLowerCase()})`))
      .domain(Object.values(TemTypeEnum));

    const pieGen = d3
      .pie<any, TemTypeAggregate>()
      .sort(null)
      .value(d => d.count);

    const sectorGen = d3
      .arc<any, PieArcDatum<TemTypeAggregate>>()
      .innerRadius(radius - 100)
      .outerRadius(radius);

    const labelGen = d3
      .arc<any, PieArcDatum<TemTypeAggregate>>()
      .innerRadius(radius - 70)
      .outerRadius(radius);

    updateRef({ container, colorScale, pieGen, sectorGen, labelGen });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { container, colorScale, pieGen, sectorGen, labelGen } = ref;
    if (!temtem || !container || !colorScale || !pieGen || !sectorGen || !labelGen) { return; }

    // TODO: Debounce data changes?
    const data = pieGen(Temtem.groupByType(temtem));

    container
      .selectAll('path')
      .data(data)
      .join(
        enter => {
          return enter.insert('path')
            .attr('fill', d => colorScale(d.data.type))
            .attr('d', sectorGen)
            .classed('tem-type-wedge', true)
            .property('prev', d => d);
        },
        update => {
          return update.transition()
            .duration(500)
            .attrTween('d', function(this: any, d) { return arcTween(this, d, sectorGen); });
        },
        exit => exit.remove()
      );
    
    container
      .selectAll('text')
      .data(data)
      .join(
        enter => {
          return enter.append('text')
            .attr('text-anchor', 'middle')
            .attr('transform', d => `translate(${labelGen.centroid(d)})`)
            .text(d => d.value > 0 ? d.value : '');
        },
        update => {
          return update
            .transition()
            .attr('transform', d => `translate(${labelGen.centroid(d)})`)
            .delay(250)
            .text(d => d.value > 0 ? d.value : '');
        },
        exit => exit.remove()
      );
      
  }, [temtem, ref]);

  return <svg className="tem-type-chart" ref={svgRef}></svg>;
}

import { useState } from 'react';

import { Temtem } from 'models';

import './tem-search.scss';
// type TemFilterMode = (tem: Temtem, filter: TemFilter) => boolean;
// const TemFilters: TemFilterMode[] = [
//   (t, f) => !f.name || t.name.toLowerCase().includes(f.name!.toLowerCase()),
//   (t, f) => !f.types || t.types.some(type => f.types!.includes(type))
// ];
  // const filtered = temtem?.filter(t => TemFilters.every(f => f(t, filter))) ?? null;

interface TemSearchProps {
  temtem: Temtem[];
  onFiltered: (filtered: Temtem[]) => void;
}

// TODO: Implement more controls (multi-select type, etc.)
// TODO: Implement sorting (by name, type, stats)
export function TemSearch({ temtem, onFiltered }: TemSearchProps) {
  const [search, setSearch] = useState('');

  function handleChange(v: string) {
    const copy = temtem.slice();

    const filtered = copy.filter(t => t.name.toLowerCase().includes(v.toLowerCase()));

    // TODO: Debounce search?
    onFiltered(filtered);
    setSearch(v);
  }

  return (
    <div className="tem-search">
      <input type="text" value={search} onChange={e => handleChange(e.target.value)} />
    </div>
  );
  
}
